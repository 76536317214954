
import { ref, defineComponent } from 'vue'
import Tooltip from '@/components/UI/Tooltip.vue'
export default defineComponent({
  emits: ['onCheck'],
  props: {
    id: {
      type: Number,
      default: 0
    },
    checked: {
      type: [Boolean, Number],
      default: false || 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableifRequired: {
      type: Boolean,
      default: false
    },
    packMembers: {
      type: Array,
      default: () => []
    },
    required: {
      type: Array,
      default: () => []
    },
    isSuperAdminCheckbox: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Tooltip
  },
  setup(props, { emit }) {
    const activeOption = ref(false)
    const onCheck = (item, packMembers, required) => {
      emit('onCheck', { option: item, packMembers: packMembers, required: required })
    }
    return { activeOption, onCheck }
  }
})
